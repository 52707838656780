import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Loading, Checkbox, Select, Tooltip } from 'sw-ui';

import DisabledAccount from '../../../../components/DisabledAccount';
import SalesReport from '../../../../components/SalesReport';
import NoResult from '../../../../components/NoResult';
import AccountName from './accountName';
import CommentComponent from './comment';
import AutomaticSendInvoices from './automaticSendInvoices';
import Aggregation from './aggregation';
import { ExtendedInfo } from './extendedInfo';
import SetingsPlacementIssuance from './setingsPlacementIssuance';

import AccountTypeDialog from '../account/dialogs/accountTypeDialog';
import AddCompanyDialog from './dialogs/newCompany';
import AirlineRateDialog from './dialogs/airlineRate';
import TrainRateDialog from './dialogs/trainRate';
import BusRateDialog from './dialogs/buslRate';
import HotelRateDialog from './dialogs/hotelRate';
import PlanFeeSettings from './dialogs/planFeeSettings';
import AccountSettings from './dialogs/accountSettings';
import EstablishedLimits from './dialogs/establishedLimits';
import DisableAccountDialog from './dialogs/disableAccountDialog';
import { CalculateCurrency } from './dialogs/calculateCurrency';
import EventApplication from './dialogs/eventApplication/eventApplication';

import { formatDate } from '../../../../utils/date';

import {
  RATETYPE,
  AIRLINECHANGERATE,
  TRAINORDERRATE,
  TRAINCANCELRATE,
  HOTELORDERRATE,
  TRAIN_CANCEL_RATE_FOR_SMARTAGENT,
  AIRLINE_CHANGE_RATE_FOR_SMARTAGENT,
  BUS_ORDER_RATE,
  TRAIN_ORDER_FOR_SMARTAGENT,
  AIRLINE_ORDER_RATE_FOR_SMARTAGENT,
  AIRLINEORDERRATE,
} from '../../../../constants/rateTypes';
import { FULLTIME } from '../../../../constants/time';
import COMPONENTS from '../../../../bi/constants/components';
import { ACCOUNTTEXT, ACCOUNTFIELDS, LEAD_TYPE, ACCOUNT_SETTINGS_FIELDS } from '../../../../bi/constants/account';
import { AGGREGATORS_NAME } from '../../../../bi/constants/aggregation';
import { SEARCH_STATUSES_EVENTS } from '../../../../bi/constants/company';

import styles from './styles/index.module.css';

const LABELS = {
  USER: 'Пользователь:',
  ADDUSER: 'ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ',
  HIDDENTARIFF: 'Скрытый',
  GO_TO_AMO: 'Перейти в AMO',
  AGGREGATION: 'Головной аккаунт',
  CALCULATE_CURRENCY: 'Калькулятор валют',
  YES: 'Да',
  NO: 'Нет',
  ENABLED: 'Включено',
  TURNED_OFF: 'Выключено',
  BOOKING_THE_PASSENGER_SCHEME: 'Бронирование по схеме пассажира:',
  ICONS: {
    VERIFIEDUSER: 'verified_user',
    VERIFIEDUSER_TOOLTIP: 'Главный аккаунт в группе компаний',
  },
  FARES_FOR_AIR_TICKETS: {
    TITLE: 'Тариф для а/б:',
    STATEMENT: 'Выписка - ',
    CHANGE: 'Изменение - ',
    SUMMATION: 'Сложение - ',
  },
  FARES_FOR_BUS_TICKETS: {
    TITLE: 'Тариф для авт/б:',
    STATEMENT: 'Выписка - ',
  },
  CHAPTERS: {
    LIMITS: 'Установленные лимиты',
  },
  APPLICATION_EVENT: 'Заявки на мероприятия',
  SMART_AGENT: 'Smartagent',
  SMARTWAY_KZ: 'Smartway KZ',
  DISABLED_BUTTON: {
    BLOCK: 'Заблокировать аккаунт',
    UNBLOCK: 'Разблокировать аккаунт',
  },
  NO_RATES: '0 шт.',
  PERCENT: '%',
  RUB: ' руб.',
  RATE_3D: 'Тариф для 3D (отели):',
  PIECES: 'шт.',
  LEAD_TYPE: 'Клиент от: ',
};

const AMO_LINK_ERROR = 'Сделка не найдена';

class Account extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    accountService: PropTypes.object.isRequired,
    companyService: PropTypes.object.isRequired,
    notificationsService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    toAnotherCompany: PropTypes.func.isRequired,
    tripService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { accountService, companyService, accountId, companyId, featureFlagsService } = props;
    const {
      account: { Id },
      planFeeSettings,
      showPlanFeeDialog,
      showAccountSettingsDialog,
      showEstablishedLimitsDialog,
      amoId,
      loadAmoId,
      aggregatorId,
      loadingAggregation,
      specialAccount,
      loadingAccountSettings,
      loadingLimits,
      CompanieForLimits,
      loadingAccountDisabled,
      isSmartAgent,
      isSmartwayKZ,
      currencyInfo,
      leadType,
    } = accountService.get();

    const { AggregatorChange, AccessSettingsChange, TariffChange, LeadType } = featureFlagsService.get();

    const { companyDebt } = companyService.get();

    const commonState = {
      showAddCompanyDialog: false,
      rateDialog: '',
      accessSettingsChange: AccessSettingsChange,
      CompanieForLimits,
      planFeeSettings,
      showPlanFeeDialog,
      showAccountSettingsDialog,
      showEstablishedLimitsDialog,
      showEventApplication: false,
      showCalculateCurrencyDialog: false,
      amoId,
      loadAmoId,
      aggregatorId,
      loadingAggregation,
      aggregatorChange: AggregatorChange,
      tariffChange: TariffChange,
      showAmoLinkDialog: false,
      specialAccount,
      loadingAccountSettings,
      loadingLimits,
      loadingAccountDisabled,
      isSmartAgent,
      isSmartwayKZ,
      showDialogDisablingAccount: false,
      isHotelChange: featureFlagsService.getHotelRestrictionChange(),
      companyDebt,
      currencyInfo,
      events: null,
      loadingEvents: false,
      PassengerScheme: false,
      leadType,
      hasAccessToLeadType: LeadType,
    };

    // если условие верно, то переход сделан в пределах аккаунта
    if (accountId === Id) {
      this.state = {
        ...commonState,
        account: { ...accountService.get().account },
        company: { ...accountService.get().company },
        specialAccount: { ...accountService.get().specialAccount },
        loading: false,
        companyDebt,
        leadType,
      };
    }

    if (accountId !== Id) {
      this.state = {
        ...commonState,
        account: null,
        company: null,
        specialAccount: null,
        loading: true,
        companyDebt: null,
        leadType: '',
      };
    }

    if (!companyService.get().accountTypeList.length) {
      companyService.getAccountType();
    }

    if (!companyDebt) {
      companyService.loadCompanyDebt(companyId);
    }

    if (!leadType) {
      accountService.getLeadType(accountId);
    }
  }

  componentDidMount() {
    const { accountService, featureFlagsService, companyService, accountId } = this.props;

    accountService.loadAccountData(accountId).finally(() => this.setState({ loading: false }));
    accountService.loadAccountPassengerScheme(accountId).then((data) => { this.PassengerScheme = data; });

    this.unsubscribe = accountService.subscribe(this.update);
    this.unsubFeatureFlags = featureFlagsService.subscribe(this.updateFeatureFlags);
    this.unsubscribeDebt = companyService.subscribe(this.updateDebt);
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubFeatureFlags();
    this.unsubscribeDebt();
  }

  update = ({
    account,
    company,
    CompanieForLimits,
    planFeeSettings,
    accountSettings,
    showPlanFeeDialog,
    showAccountSettingsDialog,
    showEstablishedLimitsDialog,
    amoId,
    loadAmoId,
    aggregatorId,
    loadingAggregation,
    specialAccount,
    loadingAccountSettings,
    loadingLimits,
    loadingAccountDisabled,
    isSmartAgent,
    isSmartwayKZ,
    currencyInfo,
    events,
    leadType,
  }) =>
    this.setState({
      account,
      company,
      CompanieForLimits,
      planFeeSettings,
      accountSettings,
      showPlanFeeDialog,
      showAccountSettingsDialog,
      showEstablishedLimitsDialog,
      amoId,
      loadAmoId,
      aggregatorId,
      loadingAggregation,
      specialAccount,
      loadingAccountSettings,
      loadingLimits,
      loadingAccountDisabled,
      isSmartAgent,
      isSmartwayKZ,
      currencyInfo,
      events,
      leadType,
    });

  updateFeatureFlags = ({
    AggregatorChange,
    AccessSettingsChange,
    HotelRestrictionChange,
    LeadType,
    AcquiringSettingChange,
  }) => {
    this.setState({
      acquiringSettingChange: AcquiringSettingChange,
      aggregatorChange: AggregatorChange,
      accessSettingsChange: AccessSettingsChange,
      isHotelChange: HotelRestrictionChange,
      hasAccessToLeadType: LeadType,
    });
  };

  promisedSetState = newState => new Promise(resolve => this.setState(newState, resolve));

  updateDebt = ({ companyDebt }) => this.setState({ companyDebt });

  updateCompanyFunds = companyId => this.props.accountService.loadCompanyFunds(companyId);

  updateCompanyDebt = companyId => this.props.companyService.loadCompanyDebt(companyId)

  toggleAddCompanyDialog = () =>
    this.setState({ showAddCompanyDialog: !this.state.showAddCompanyDialog });

  toggleRateDialog = type =>
    this.setState({
      rateDialog: this.state.rateDialog ? '' : type,
    });

  toggleTestAccount = () => {
    const newTest = !this.state.account.Test;

    this.handleSaveAccount('Test', newTest);
  };

  handleChangeAccountSettings = (section, name) => {
    const { accountSettings } = this.state;

    this.setState({
      accountSettings: {
        ...accountSettings,
        [section]: {
          ...accountSettings[section],
          [name]: !accountSettings[section][name],
        },
      } });
  };

  handleChangeSelect = (section, name, item) => {
    const { accountSettings } = this.state;

    this.setState({
      accountSettings: {
        ...accountSettings,
        [section]: {
          ...accountSettings[section],
          [name]: item.value,
        },
      },
    });
  };

  handleClosePlanFeeDialog = () => {
    const { accountService, accountId } = this.props;
    accountService.closePlanFeeSettingsDialog(accountId);
  };

  handleShowPlanFeeDialog = () => this.props.accountService.showPlanFeeSettingsDialog();

  handleCloseAccountSettingsDialog = () => this.props.accountService.closeAccountSettingsDialog();

  handleShowAccountSettings = async () => {
    const {
      accountService,
      accountId,
    } = this.props;

    accountService.updateLoadingAccountSettings(true);
    accountService.showAccountSettingsDialog();

    await accountService.loadAccountSettings(accountId);
    accountService.updateLoadingAccountSettings(false);
  };

  handleShowEstablishedLimits = async () => {
    const { accountService, accountId } = this.props;
    accountService.showEstablishedLimitsDialog();

    if (!this.state.showEstablishedLimitsDialog) {
      accountService.updateLoadingLimits(true);

      try {
        await accountService.loadAccountForEstablishedLimits(accountId);
        accountService.updateLoadingLimits(false);
      } catch (e) {
        accountService.updateLoadingLimits(true);
      }
    }
  };

  handleShowApplicationEvent = async () => {
    const { accountId, tripService } = this.props;
    const { showEventApplication: value } = this.state;

    this.setState({ showEventApplication: !value });

    if (!value) {
      await this.promisedSetState({ loadingEvents: true });

      await tripService.getApplicationFullEvents({ AccountId: accountId, Statuses: SEARCH_STATUSES_EVENTS });
      const { fullEvents } = await tripService.get();

      await this.promisedSetState({ events: fullEvents });
      await this.promisedSetState({ loadingEvents: false });
    }
  };

  handleChangeCompany = ({ value }) => {
    this.props.toAnotherCompany(value);
    this.updateCompanyFunds(value);
    this.updateCompanyDebt(value);
  };

  handleAddCompany = company =>
    this.props.accountService.addCompany(company)
      .then(() => {
        const { notificationsService: notifier } = this.props;
        this.toggleAddCompanyDialog();

        this.props.notificationsService.send({
          level: notifier.levels.get('SUCCESS'),
          message: 'Организация успешно создана',
        });
      })
      .catch(() => {
        const { notificationsService: notifier } = this.props;

        this.props.notificationsService.send({
          level: notifier.levels.get('FAILURE'),
          message: 'Не удалось создать организацию',
        });
      });

  handleSaveAccount = (field, value) => {
    const { accountService } = this.props;
    const account = this.state.account;

    account[field] = value;

    return accountService.updateAccount(account);
  };

  handleSaveEditedAccount = (data) => {
    const { accountService } = this.props;

    const newDate = data;
    newDate.CreateDate = newDate.CreateDate ? formatDate(data.CreateDate, FULLTIME) : '';

    const account = {
      ...this.state.account,
      ...newDate,
    };

    return accountService.updateAccount(account);
  };

  handleSaveEditedAccountType = (accountType) => {
    const { accountService } = this.props;

    const account = {
      ...this.state.account,
      AccountType: accountType,
    };

    return accountService.updateAccount(account);
  };

  handleSavePlanFeeSettings = planFeeSettings =>
    this.props.accountService.updatePlanFeeSettings(this.state.account.Id, planFeeSettings);

  handleSaveAccountSettings = () => this.props.accountService.updateAccountSettings(this.state.accountSettings);

  handleChangePlanFeeSettings = (value, field, index) =>
    this.props.accountService.changePlanFeeSettings(value, field, index);

  handleRemovePlanFee = index => this.props.accountService.removePlanFeeSettings(index);

  handleAddPlanFee = () => this.props.accountService.addPlanFeeSettingEntity();

  handleSaveEditedSendInvoices = (value) => {
    const { accountService } = this.props;

    const account = {
      ...this.state.account,
      DisableAccountInvoice: value,
    };

    return accountService.updateAccount(account);
  };

  handleDownloadSalesReport = (startDate, endDate, type) => {
    const { accountService, accountId } = this.props;

    return accountService.downloadSalesReport(accountId, startDate, endDate, type);
  };

  handleShowAmoLinkDialog = showAmoLinkDialog => this.setState({ showAmoLinkDialog });

  handleGetAmoId = async () => {
    const { accountService, accountId } = this.props;
    await accountService.getAmoId(accountId);

    const { amoId } = accountService.get();

    if (amoId) {
      window.open(`https://smartwaytoday.amocrm.ru/leads/detail/${amoId}`);
    } else {
      this.handleShowAmoLinkDialog(true);
    }
  };

  handleUpdateAggregation = value => this.props.accountService.updateAggregation(value);

  handleGetExtendedInfo = accountId => this.props.accountService.getExtendedInfo(accountId);

  handleUpdateExtendedInfo = (accountId, info) => this.props.accountService.updateExtendedInfo(accountId, info);

  handleToggleDisableAccount = () => this.setState({ showDialogDisablingAccount: !this.state.showDialogDisablingAccount })

  updateEvents = () => {
    const { fullEvents } = this.props.tripService.get();

    this.setState({
      events: fullEvents,
    });
  };
  handleSaveDisalbeAccount = () => {
    this.props.accountService.accountDisabled(this.state.account.Id)
      .then(() => this.handleToggleDisableAccount());
  }

  handleChangeDialogCalculateCurrency = async () => {
    this.setState({
      showCalculateCurrencyDialog: !this.state.showCalculateCurrencyDialog,
    });
  }

  handleChangeLeadType = ({ value }) => {
    const { accountService, accountId } = this.props;
    const { changeLeadType, saveLeadType, deleteLeadType } = accountService;

    changeLeadType(value);

    if (!value) {
      return deleteLeadType(accountId);
    }

    return saveLeadType(accountId, value);
  }

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading size='large' />
    </div>
  );

  renderIcon = (mainAccountId, AccountId) => {
    if (mainAccountId && mainAccountId === AccountId) {
      return (
        <div>
          <span>
            <i className='material-icons' >
              { LABELS.ICONS.VERIFIEDUSER }
            </i>
            <Tooltip
              position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
            >
              { LABELS.ICONS.VERIFIEDUSER_TOOLTIP }
            </Tooltip>
          </span>
        </div>
      );
    }

    return null;
  };

  renderGroupCompany = () => {
    const { Accounts, MainAccountId } = this.state.account.companiesGroup;

    const renderCompanies = Accounts.map(({ AccountName: Name, AccountId, FirstCompanyId }) => (
      <div className={ styles.companyName } key={ AccountId }>
        <div>
          <a href={ `/account/${AccountId}/company/${FirstCompanyId}/account/profile` }>{`${Name || ACCOUNTTEXT.NOINDICATED} (${AccountId})`}</a>
        </div>
        {this.renderIcon(MainAccountId, AccountId)}
      </div>
    ));

    return (
      <div className={ styles.row }>
        <span className={ styles.name }>Группа компаний: </span>
        <div className={ styles.left }>
          { renderCompanies }
        </div>
      </div>
    );
  }

  disabledButtonHtml = () => {
    const { account: { Enabled }, loadingAccountDisabled } = this.state;
    const { featureFlagsService: { getCompanySuspend } } = this.props;

    const label = Enabled ? LABELS.DISABLED_BUTTON.BLOCK : LABELS.DISABLED_BUTTON.UNBLOCK;
    const disable = !loadingAccountDisabled || !getCompanySuspend();

    return (
      <div>
        <Button
          label={ label }
          className={ styles.accountDisableButton }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          disabled={ disable }
          onClick={ this.handleToggleDisableAccount }
        />
      </div>
    );
  };

  renderLeadTypeSelector = () => {
    const { hasAccessToLeadType, leadType } = this.state;

    if (!hasAccessToLeadType) return null;

    return (
      <div className={ styles.lead_type }>
        <Select
          label={ LABELS.LEAD_TYPE }
          items={ LEAD_TYPE }
          value={ leadType }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ this.handleChangeLeadType }
        />
      </div>
    );
  }

  renderAttrAccount = () => {
    const { isSmartwayKZ, isSmartAgent } = this.state;

    if (!isSmartwayKZ && !isSmartAgent) return null;

    if (isSmartwayKZ) {
      return (
        <div className={ styles.smartway_kz }>
          { LABELS.SMARTWAY_KZ }
        </div>
      );
    }

    return (
      <div className={ styles.smart_agent }>
        { LABELS.SMART_AGENT }
      </div>
    );
  };

  renderButtonCalculateCurrency = () => {
    const { featureFlagsService: { getHotelCalculateCurrency } } = this.props;

    if (!getHotelCalculateCurrency()) return null;

    return (
      <Button
        onClick={ this.handleChangeDialogCalculateCurrency }
        theme={ COMPONENTS.BUTTON.THEME.SECOND }
        label={ LABELS.CALCULATE_CURRENCY }
        className={ styles.calculation }
      />
    );
  }

  renderCalculateCurrency = () => {
    const { accountService, accountId } = this.props;
    const { showCalculateCurrencyDialog, currencyInfo } = this.state;

    if (!showCalculateCurrencyDialog) return null;

    return (
      <CalculateCurrency
        currencyInfo={ currencyInfo }
        accountId={ accountId }
        onClose={ this.handleChangeDialogCalculateCurrency }
        accountService={ accountService }
      />
    );
  }

  renderTopPanel = (account) => {
    const {
      loadAmoId,
      loadingAggregation,
      aggregatorId,
      aggregatorChange,
      isSmartAgent,
      account: { companiesGroup },
      isHotelChange,
    } = this.state;
    const {
      accountService,
      accountId,
      featureFlagsService: {
        getTariffChange,
      },
    } = this.props;
    const {
      Rates: {
        Airline: {
          Order,
          Change,
          SummationFees,
        },
        Bus,
        Train,
        Hotel,
      },
      Enabled,
      Companies,
      Comment,
      Test,
      Name,
      Id,
      IntercomComment,
    } = account;
    const { Cancel } = Train;

    const airlineTariffOrder = isSmartAgent ? AIRLINE_ORDER_RATE_FOR_SMARTAGENT : AIRLINEORDERRATE;

    const tariffOrderAirline = airlineTariffOrder[Order] || LABELS.HIDDENTARIFF;

    const airlineChangeRate = isSmartAgent ? AIRLINE_CHANGE_RATE_FOR_SMARTAGENT : AIRLINECHANGERATE;

    const trainOrderRate = isSmartAgent ? TRAIN_ORDER_FOR_SMARTAGENT : TRAINORDERRATE;

    const tariffChangeAirline = airlineChangeRate[Change] || LABELS.HIDDENTARIFF;

    const tariffOrderTrain = trainOrderRate[Train.Order] || LABELS.HIDDENTARIFF;

    const tariffOrderBus = Bus ? BUS_ORDER_RATE[Bus.RateType] : LABELS.HIDDENTARIFF;

    const trainCancelTariffForSmartagent = isSmartAgent
      ? TRAIN_CANCEL_RATE_FOR_SMARTAGENT[Cancel]
      : TRAINCANCELRATE[Cancel];

    const tariffCancelTrain = typeof trainCancelTariffForSmartagent === 'number'
      ? trainCancelTariffForSmartagent
      : LABELS.HIDDENTARIFF;

    const tariffOrderHotel = HOTELORDERRATE[Hotel] || LABELS.HIDDENTARIFF;

    const renderAccountSettings = (
      <div className={ styles.row }>
        <div
          className={ `${styles.name} ${styles.account_settings}` }
          onClick={ this.handleShowAccountSettings }
        >
          Настройки аккаунта
        </div>
      </div>
    );

    const renderBusRates = () => (
      <div className={ styles.row }>
        <div>
          <span className={ styles.name }>{ LABELS.FARES_FOR_BUS_TICKETS.TITLE }</span>
          <span>
            <i>{ LABELS.FARES_FOR_BUS_TICKETS.STATEMENT }</i><b className={ styles.value }>{ tariffOrderBus }</b>
          </span>
        </div>
        <Button
          label={ ACCOUNTTEXT.EDIT }
          disabled={ !getTariffChange() }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ () => this.toggleRateDialog(RATETYPE.BUS) }
        />
      </div>
    );

    const renderEstablishedLimits = (
      <div className={ styles.row }>
        <div
          className={ `${styles.name} ${styles.account_settings}` }
          onClick={ this.handleShowEstablishedLimits }
        >
          { LABELS.CHAPTERS.LIMITS }
        </div>
      </div>
    );

    const renderEventApplication = (
      <div className={ styles.row }>
        <div
          className={ `${styles.name} ${styles.account_settings}` }
          onClick={ this.handleShowApplicationEvent }
        >
          { LABELS.APPLICATION_EVENT}
        </div>
      </div>
    );

    const renderInfoHotelContracts = () => {
      const { account: { Rates: { Hotels3d } } } = this.state;

      if (!Hotels3d || !Hotels3d.length) {
        return <div>{ LABELS.NO_RATES }</div>;
      }

      const contractsHtml = Hotels3d.map(({ Commission, IsPercent, TotalCount }) => {
        const signCommission = IsPercent ? LABELS.PERCENT : LABELS.RUB;

        return (
          <span><i>{TotalCount}{LABELS.PIECES}</i> - <b className={ styles.value }>{Commission}{signCommission}</b></span>
        );
      }).reduce((prev, value) => [prev, '; ', value]);

      return <span>{ contractsHtml }</span>;
    };

    const companiesName = Companies.length ? Companies[0].ShortCompanyName : '';
    const companiesPassengerScheme = this.PassengerScheme ? LABELS.ENABLED : LABELS.TURNED_OFF;

    return (
      <div className={ styles.top }>
        <div className={ styles.left }>
          <DisabledAccount disabled={ !Enabled } />
          <div className={ styles.row }>
            <span className={ styles.account }>{ Name || ACCOUNTTEXT.NOINDICATED } ({ Id })</span>
            { this.renderAttrAccount() }
            <Button
              className={ styles.amo_btn }
              onClick={ this.handleGetAmoId }
              theme={ COMPONENTS.BUTTON.THEME.SECOND }
              label={ LABELS.GO_TO_AMO }
              loading={ loadAmoId }
              disabled={ loadAmoId }
            />
            {this.renderIcon(companiesGroup.MainAccountId, Id)}
          </div>
          <AccountName
            account={ account }
            onSave={ this.handleSaveEditedAccount }
            accountAutocomplete={ accountService.accountAutocomplete }
            addCompanyToGroup={ accountService.addAccountToCompaniesGroup }
            removeAccountFromCompaniesGroup={ accountService.removeAccountFromCompaniesGroup }
            getInfoAboutCompaniesGroup={ accountService.getInfoAboutCompaniesGroup }
            getAccountSupports={ accountService.loadAccountSupports }
            companiesGroup={ companiesGroup }
          />
          {this.renderGroupCompany()}
          <AccountTypeDialog
            account={ account }
            onSave={ this.handleSaveEditedAccountType }
          />
          <ExtendedInfo
            getInfo={ () => this.handleGetExtendedInfo(Id) }
            onSave={ info => this.handleUpdateExtendedInfo(Id, info) }
          />
          <div className={ `${styles.row} ${styles.amplitude}` }>
            <span className={ styles.name }>Название в amplitude:</span>
            <b className={ styles.value }>{ companiesName }</b>
          </div>
          <CommentComponent
            field={ ACCOUNTFIELDS.COMMENT }
            comment={ Comment }
            onSave={ this.handleSaveAccount }
          />
          <CommentComponent
            field={ ACCOUNTFIELDS.INTERCOM }
            comment={ IntercomComment }
            onSave={ this.handleSaveAccount }
          />
          <div className={ styles.row }>
            <div>
              <span className={ styles.name }>{ LABELS.FARES_FOR_AIR_TICKETS.TITLE }</span>
              <span>
                <i>{ LABELS.FARES_FOR_AIR_TICKETS.STATEMENT }</i><b className={ styles.value }>{ tariffOrderAirline }</b>
                <i>{ LABELS.FARES_FOR_AIR_TICKETS.CHANGE }</i><b className={ styles.value }>{ tariffChangeAirline }</b>
                <i>{ LABELS.FARES_FOR_AIR_TICKETS.SUMMATION }</i><b className={ styles.value }>{ SummationFees ? LABELS.YES : LABELS.NO }</b>
              </span>
            </div>
            <Button
              label={ ACCOUNTTEXT.EDIT }
              disabled={ !getTariffChange() }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ () => this.toggleRateDialog(RATETYPE.AIRLINE) }
            />
          </div>
          <div className={ styles.row }>
            <div>
              <span className={ styles.name }>Тариф для ж/д:</span>
              <span>
                <i>Выписка - </i><b className={ styles.value }>{ tariffOrderTrain }</b>
                <i>Возврат - </i><b className={ styles.value }>{ tariffCancelTrain }</b>
              </span>
            </div>
            <Button
              label={ ACCOUNTTEXT.EDIT }
              disabled={ !getTariffChange() }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ () => this.toggleRateDialog(RATETYPE.TRAIN) }
            />
          </div>
          { renderBusRates() }
          <div className={ styles.row }>
            <div>
              <span className={ styles.name }>Тариф для гостиниц:</span>
              <span>
                <i>Выписка - </i><b className={ styles.value }>{ tariffOrderHotel }</b>
              </span>
            </div>
            <Button
              label={ ACCOUNTTEXT.EDIT }
              disabled={ !getTariffChange() }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ () => this.toggleRateDialog(RATETYPE.HOTEL) }
            />
          </div>
          <div className={ styles.row }>
            <span className={ styles.name }>{ LABELS.RATE_3D }</span>
            { renderInfoHotelContracts() }
          </div>
          <div className={ styles.row }>
            <div className={ `${styles.name} ${styles.plan_fee}` } onClick={ this.handleShowPlanFeeDialog }>
              Настройки автосписания абонентской платы
            </div>
          </div>
          <div className={ `${styles.row} ${styles.amplitude}` }>
            <span className={ styles.name }>{ LABELS.BOOKING_THE_PASSENGER_SCHEME }</span>
            <b className={ styles.value }>{ companiesPassengerScheme }</b>
          </div>
          { renderAccountSettings }
          { renderEstablishedLimits }
          { renderEventApplication }
          <AutomaticSendInvoices
            account={ account }
            onSave={ this.handleSaveEditedSendInvoices }
          />
          <SetingsPlacementIssuance
            accountService={ accountService }
            accountId={ accountId }
            isHotelChange={ isHotelChange }
          />
          <SalesReport
            onDownload={ this.handleDownloadSalesReport }
          />
        </div>
        <div className={ styles.right }>
          <div className={ styles.test }>
            <Checkbox
              value={ Test }
              onChange={ this.toggleTestAccount }
            />
            <span>Тестовый&nbsp;аккаунт</span>
          </div>
          <Aggregation
            loading={ loadingAggregation }
            disabled={ !aggregatorChange }
            aggregator={ aggregatorId }
            onUpdateAggregation={ this.handleUpdateAggregation }
            label={ LABELS.AGGREGATION }
            items={ AGGREGATORS_NAME }
          />
          { this.renderButtonCalculateCurrency() }
          { this.disabledButtonHtml() }
          { this.renderLeadTypeSelector() }
        </div>
      </div>
    );
  };

  renderPlanFeeDialog = (account) => {
    const { planFeeSettings } = this.state;
    const { featureFlagsService: { getTariffChange } } = this.props;
    const { Companies } = account;

    return (
      <PlanFeeSettings
        companies={ Companies }
        planFeeSettings={ planFeeSettings }
        onSave={ this.handleSavePlanFeeSettings }
        onClose={ this.handleClosePlanFeeDialog }
        onChange={ this.handleChangePlanFeeSettings }
        onRemovePlanFee={ this.handleRemovePlanFee }
        onAddPlanFee={ this.handleAddPlanFee }
        tariffFlag={ getTariffChange() }
      />
    );
  };

  renderAccountSettingsDialog = () => {
    const { aggregatorId, accountSettings, loadingAccountSettings, accessSettingsChange, acquiringSettingChange } = this.state;

    const hideFields = {
      [ACCOUNT_SETTINGS_FIELDS.ACQUIRING_ACCESS]: acquiringSettingChange,
    };

    return (
      <AccountSettings
        loading={ loadingAccountSettings }
        aggregationId={ aggregatorId }
        accountSettings={ accountSettings }
        accessSettingsChange={ accessSettingsChange }
        onChange={ this.handleChangeAccountSettings }
        onSave={ this.handleSaveAccountSettings }
        onClose={ this.handleCloseAccountSettingsDialog }
        onChangeSelect={ this.handleChangeSelect }
        hideSettings={ hideFields }
      />
    );
  }

  renderEstablishedLimitsDialog = () => {
    const { CompanieForLimits, loadingLimits } = this.state;

    return (
      <EstablishedLimits
        onClose={ this.handleShowEstablishedLimits }
        companies={ CompanieForLimits }
        loading={ loadingLimits }
      />
    );
  };

  renderEventApplicationDialog = () => {
    const {
      tripService: {
        cancelApplicationEvents,
        deleteApplicationEvent,
        sortingApplicationEvent,
      },
    } = this.props;
    const {
      showEventApplication,
      loadingEvents,
      events,
    } = this.state;


    const onCancel = async (id) => {
      await cancelApplicationEvents(id);
      this.updateEvents();
    };

    const onSort = async (type) => {
      await sortingApplicationEvent(type);
      this.updateEvents();
    };

    const onDelete = async (id) => {
      await deleteApplicationEvent(id);
      this.updateEvents();
    };

    return (
      <EventApplication
        show={ showEventApplication }
        events={ events }
        loading={ loadingEvents }
        onCalcelled={ onCancel }
        onDelete={ onDelete }
        onSort={ onSort }
        onClose={ this.handleShowApplicationEvent }
      />
    );
  }

  renderDisableAccountDialog = () => {
    const { account, loadingAccountDisabled } = this.state;

    return (
      <DisableAccountDialog
        onClose={ this.handleToggleDisableAccount }
        onSave={ this.handleSaveDisalbeAccount }
        account={ account }
        loading={ loadingAccountDisabled }
      />
    );
  };

  renderBottomPanel = (account, companyId) => {
    const { companyDebt } = this.state;

    const debtCompanyHtml = companyDebt ? (
      <div className={ styles.bottom__debt }>Задолженность. Лимит не давать, билеты не выписывать!</div>
    ) : null;

    return (
      <div className={ styles.bottom }>
        <div className={ styles.bottom__action }>
          <div className={ styles.row }>
            <span>{LABELS.USER}</span>
            <div className={ styles.select }>
              { account.Companies.length === 1 ?
                (account.Companies[0].ShortCompanyName || account.Companies[0].CompanyName) :
                <Select
                  overflow
                  items={ account.Companies.map(company => ({
                    value: company.CompanyId,
                    label: company.ShortCompanyName || company.CompanyName,
                  })) }
                  value={ companyId }
                  theme={ COMPONENTS.SELECT.THEME.BORDER }
                  onChange={ this.handleChangeCompany }
                />
              }
            </div>
            <div className={ styles.add }>
              <i className='material-icons'>
                add
              </i>
              <Button
                label={ LABELS.ADDUSER }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                className={ styles['add-button'] }
                onClick={ this.toggleAddCompanyDialog }
              />
            </div>
          </div>
        </div>
        { debtCompanyHtml }
      </div>
    );
  };

  render() {
    const { companyId, companyService, accountService } = this.props;
    const {
      loading,
      account,
      isSmartAgent,
      showAddCompanyDialog,
      rateDialog,
      showPlanFeeDialog,
      showAccountSettingsDialog,
      showEstablishedLimitsDialog,
      showAmoLinkDialog,
      showDialogDisablingAccount,
      showEventApplication,
    } = this.state;

    const addCompanyDialog = showAddCompanyDialog ? (
      <AddCompanyDialog
        companyService={ companyService }
        onAdd={ this.handleAddCompany }
        onClose={ this.toggleAddCompanyDialog }
      />
    ) : null;

    let dialog = null;
    switch (rateDialog) {
      case RATETYPE.AIRLINE: {
        dialog = (
          <AirlineRateDialog
            isSmartAgent={ isSmartAgent }
            accountService={ accountService }
            currentRate={ account.Rates.Airline }
            onClose={ this.toggleRateDialog }
          />
        );
        break;
      }
      case RATETYPE.TRAIN: {
        dialog = (
          <TrainRateDialog
            isSmartAgent={ isSmartAgent }
            accountService={ accountService }
            currentRate={ account.Rates.Train }
            onClose={ this.toggleRateDialog }
          />
        );
        break;
      }
      case RATETYPE.HOTEL: {
        dialog = (
          <HotelRateDialog
            accountService={ accountService }
            currentRate={ account.Rates.Hotel }
            onClose={ this.toggleRateDialog }
          />
        );
        break;
      }
      case RATETYPE.BUS: {
        dialog = (
          <BusRateDialog
            accountService={ accountService }
            currentRate={ account.Rates.Bus }
            onClose={ this.toggleRateDialog }
          />
        );
        break;
      }
    }

    const planFeeSettingsDialog = showPlanFeeDialog && this.renderPlanFeeDialog(account);
    const accountSettingsDialog = showAccountSettingsDialog && this.renderAccountSettingsDialog();
    const establishedLimitsDialog = showEstablishedLimitsDialog && this.renderEstablishedLimitsDialog();
    const eventApplication = showEventApplication && this.renderEventApplicationDialog();
    const disableAccountDialog = showDialogDisablingAccount && this.renderDisableAccountDialog();
    const amoNoLinkDialog = showAmoLinkDialog && <NoResult message={ AMO_LINK_ERROR } onClose={ () => this.handleShowAmoLinkDialog(false) } />;

    return loading ? this.renderLoading() : (
      <div>
        { this.renderTopPanel(account) }
        { this.renderBottomPanel(account, companyId) }
        { addCompanyDialog }
        { dialog }
        { planFeeSettingsDialog }
        { accountSettingsDialog }
        { establishedLimitsDialog }
        { eventApplication }
        { disableAccountDialog }
        { amoNoLinkDialog }
        { this.renderCalculateCurrency() }
      </div>
    );
  }
}

export default Account;
