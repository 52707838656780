import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Loading, Select } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import {
  RATETYPE,
  TRAINORDERRATE,
  TRAINCANCELRATE,
  TRAIN_CANCEL_RATE_FOR_SMARTAGENT,
  TRAIN_ORDER_FOR_SMARTAGENT,
} from '../../../../../constants/rateTypes';
import COMPONENTS from '../../../../../bi/constants/components';

import styles from '../styles/dialog.module.css';

const RateTypes = {
  ORDER: 'Order',
  CANCEL: 'Cancel',
};

const WIDTH = '400px';

const IncludingVAT = '(включая НДС)';

const TARIFFS = {
  Fixed50: 'Fixed50',
  Fixed0: 'Fixed0',
};

class TrainRateDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isSmartAgent: PropTypes.bool.isRequired,
    accountService: PropTypes.object.isRequired,
    currentRate: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    this.state = {
      rates: {
        Order: [],
        Cancel: [],
      },
      rate: {
        Order: props.currentRate.Order,
        Cancel: props.currentRate.Cancel,
      },

      loading: true,
      waitingResponse: false,
    };
  }

  componentDidMount() {
    this.props.accountService.getRates(RATETYPE.TRAIN)
      .then(res => this.setState({ loading: false, rates: res }));
  }

  handleCancelRate = (value, rateType) => this.setState({
    rate: {
      ...this.state.rate,
      [rateType]: value,
    },
  });

  handleSave = () => {
    const { accountService } = this.props;
    const { rate } = this.state;

    return accountService.updateRates(RATETYPE.TRAIN, rate);
  };

  getDefaultItem = (items, rate) => (items.some(({ value }) => value === rate) ? rate : items[0].value);

  renderSelectRate = (rateType) => {
    const { rates, rate } = this.state;
    const { isSmartAgent } = this.props;

    const items = rates[rateType].reduce((acc, item) => {
      if (!isSmartAgent && rateType === RateTypes.CANCEL && typeof TRAINCANCELRATE[item] !== 'number') return acc;

      const trainCancelForSmartagent = isSmartAgent
        ? TRAIN_CANCEL_RATE_FOR_SMARTAGENT[item]
        : TRAINCANCELRATE[item];

      const trainOrderForSmartagent = isSmartAgent
        ? TRAIN_ORDER_FOR_SMARTAGENT[item]
        : TRAINORDERRATE[item];

      if (rateType === RateTypes.ORDER) {
        const label = item !== TARIFFS.Fixed0 ? `${trainOrderForSmartagent} ${IncludingVAT}` : trainOrderForSmartagent;

        if (!isSmartAgent && TRAINORDERRATE[item]) {
          return [...acc, { value: item, label }];
        }

        if (isSmartAgent && TRAIN_ORDER_FOR_SMARTAGENT[item]) {
          return [...acc, { value: item, label }];
        }

        return acc;
      }

      if (rateType === RateTypes.CANCEL) {
        return [...acc, { value: item, label: trainCancelForSmartagent }];
      }

      return acc;
    }, []);

    let title = null;
    if (rateType === RateTypes.ORDER) title = 'Выписка';
    if (rateType === RateTypes.CANCEL) title = 'Возврат';

    return (
      <div className={ styles.select }>
        <h4>{ title }:</h4>
        <Select
          items={ items }
          value={ this.getDefaultItem(items, rate[rateType]) }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ ({ value }) => this.handleCancelRate(value, rateType) }
        />
      </div>
    );
  };

  renderLoading = () => (
    <Dialog onClick={ this.props.onClose } width={ WIDTH }>
      <div className={ styles.loading }>
        <Loading size={ COMPONENTS.LOADING.SIZE.LARGE } />
      </div>
    </Dialog>
  );

  render() {
    const { onClose } = this.props;
    const { loading } = this.state;

    return loading ? this.renderLoading() : (
      <Dialog onClick={ onClose } width={ WIDTH } >
        <div className={ styles.dialog }>
          <h3>Изменение тарифа для ж/д</h3>
          { this.renderSelectRate(RateTypes.ORDER) }
          { this.renderSelectRate(RateTypes.CANCEL) }
          <DecisionButtons
            loadingIncluded
            onCancel={ onClose }
            onSave={ this.handleSave }
          />
        </div>
      </Dialog>
    );
  }
}

export default TrainRateDialog;
